import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { RadioCard } from '@hallmark/web.core.forms.radio-card';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useAnalyticsContext } from '../../context/analytics-context';
import { useAppContext, setIsSendToMe } from '../../context/app-context';
import { useInitializationDataContext } from '../../context/data-context';
import { Address, AddressTypes } from '../../global-types/addresses';
import { useIsOneToMany } from '../../hooks';
import { updateDeliveryMode } from '../../services';
import { Contact } from '../address-book';
import { FormFieldsGenerator } from '../form-fields-generator/form-fields-generator';
import { FormFields } from '../form-fields-generator/form-fields-generator-types';
import { useFormFieldGenerator } from '../form-fields-generator/hooks/useFormFieldsGenerator';
import { DeliveryModes } from './address-form-types';
import { AddressFormProps } from './address-form-types';
import styles from './address-form.module.scss';
import { getContactFormData } from './address-utils';
import { AddressBookButton, EnvelopeRadioGroup } from './fragments';
import { addressFormFieldList } from './utils/address-form-field-list';

/**
 * Lazy Address Book since it's
 * only needed for US Address cards
 */
const QuickAddressDialogComponent = lazy(() =>
  import('../../components/quick-address-dialog-container').then((module) => ({
    default: module.QuickAddressDialogContainer,
  })),
);

/**
 * Suspense fallback component
 */
const AddressBookLoader = () => <div>Loading</div>;

export const AddressForm = ({
  addressType,
  addClass,
  shouldRenderEnvelope,
  formHandlers,
  isLoadingAddresses,
  getNextPage,
  onSkipValidation,
  setSubmittedAddresses,
  setShouldSave,
}: AddressFormProps): React.ReactElement => {
  const [isQuickAddressOpen, setIsQuickAddressOpen] = useState(false);
  const classes = classNames(styles['address-form-wrapper'], addClass);
  const { trackSelectedQuickAddress } = useAnalyticsContext();

  const { t } = useTranslation();
  const {
    initializedDataState: { data: initializedData, isUS, isUK },
  } = useInitializationDataContext();
  const {
    appState: { isSendToMe },
    appDispatch,
  } = useAppContext();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const isOneToMany = useIsOneToMany();
  const projectId = initializedData?.project_id;
  const formTitle =
    addressType === AddressTypes.RECIPIENT ? `${t('addressForm.recipientTitle')}` : `${t('addressForm.returnTitle')}`;

  const { getValues, setValue, register, watch } = formHandlers;

  if (isSendToMe) {
    setValue('send_to', 'me');
    // If false, form defaults to 'them'
  }

  // Scroll address form to top when user moves betweeen recipient and return address
  useEffect(() => {
    document.querySelector('form')?.scrollIntoView();
  }, [formTitle]);

  const handleAddressBookSubmit = (newAddress: Contact) => {
    trackSelectedQuickAddress(formTitle);
    const addressFormValues = getValues();
    const contactFormData = getContactFormData(addressFormValues, newAddress);

    Object.keys(contactFormData).forEach((field) =>
      setValue(field as keyof Address, contactFormData[`${field}`], { shouldValidate: true }),
    );
  };

  const handleDeliveryModeChange = () => {
    // Store send_to in state in case user leaves/returns
    const isSendToMeChecked = getValues('send_to') === 'me';
    const deliveryMode = isSendToMeChecked ? DeliveryModes.ShipToMe : DeliveryModes.MailToRecipient;
    setIsSendToMe(appDispatch, isSendToMeChecked);
    updateDeliveryMode(deliveryMode, projectId);
  };

  const envelope = watch('envelope');

  useEffect(() => {
    if (isOneToMany) {
      const searchParams = new URLSearchParams(search);
      searchParams.set('envelope', envelope as string);
      navigate({ pathname, search: searchParams.toString() }, { replace: true });
    }
  }, [envelope]);

  const shouldHideAddressBook = shouldRenderEnvelope && envelope === 'blank';

  const formFields = useFormFieldGenerator(formHandlers, 'address', addressFormFieldList) as FormFields[];
  return (
    <div className={classes}>
      <form className={styles['address-form']} data-testid="address-form">
        <div tabIndex={-1} className={styles['address-form-content']}>
          {shouldRenderEnvelope ? (
            <EnvelopeRadioGroup register={register} />
          ) : (
            formTitle && (
              <Typography
                variant={TypographyVariants.Subtitle}
                addClass={styles.title}
                htmlTagOverride={HtmlTagOverrides.H2}
              >
                {formTitle}
              </Typography>
            )
          )}
          {isUK && (
            <div role="radiogroup" aria-labelledby="radio_button_group" className={styles['radio-cards-container']}>
              <RadioCard
                domId="send-to-me-radio-card"
                helperText="Send to Me"
                register={register('send_to', {
                  required: true,
                  onChange: handleDeliveryModeChange,
                })}
                addClass={styles['radio-card']}
                value="me"
              >
                Sent to you with a spare envelope
              </RadioCard>
              <RadioCard
                domId="send-to-them-radio-card"
                helperText="Send to Them"
                register={register('send_to', {
                  required: true,
                  onChange: handleDeliveryModeChange,
                })}
                addClass={styles['radio-card']}
                value="them"
              >
                Sent directly to the recipient
              </RadioCard>
            </div>
          )}
          {isUS && !shouldHideAddressBook && <AddressBookButton click={() => setIsQuickAddressOpen(true)} />}
          <div className={classNames(envelope === 'blank' && styles['hide-address'])}>
            <FormFieldsGenerator selectedFormFields={formFields} register={register} />
          </div>
        </div>
      </form>
      {isUS && isQuickAddressOpen && (
        <Suspense fallback={<AddressBookLoader />}>
          <QuickAddressDialogComponent
            getNextPage={getNextPage}
            formTitle={formTitle}
            handleAddressBookSubmit={handleAddressBookSubmit}
            isAddressBookOpen={isQuickAddressOpen}
            isLoadingAddresses={isLoadingAddresses}
            onCloseAddressBook={() => setIsQuickAddressOpen(false)}
            onSkipValidation={onSkipValidation}
            setSubmittedAddresses={setSubmittedAddresses}
            setShouldSave={setShouldSave}
          />
        </Suspense>
      )}
    </div>
  );
};
