import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { PhotoDrawer as PhotoDrawerComponent } from '@hallmark/web.page-components.photo-drawer';
import { thumbnailWidth } from '../../constants';
import { addPhotoTrayImages, setAllDrawersClosed, setIsToasterOpen, useAppContext } from '../../context/app-context';
import { useInitializationDataContext } from '../../context/data-context';
import { ImageResponse } from '../../global-types';
import { PhotoTrayCustomFormats } from '../../global-types/images';
import { useDeletePhotoTrayImages, useFeatureFlags, usePhotoDrawerInfiniteScroll } from '../../hooks';
import { linkPhoto } from '../../services';
import { CanvasDataTypes } from '../../utils';
import { useImageUploadHandlers } from '../card-controls/drawer-container/hooks/use-image-upload-handlers';
import { DeleteImagesConfirmationDialog } from '../delete-images-dialog/delete-images-confirmation-dialog';
import styles from './photo-drawer.module.scss';

export const PhotoDrawer = () => {
  const { appState, appDispatch } = useAppContext();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const closeAllDrawers = useCallback(() => setAllDrawersClosed(appDispatch), [appDispatch]);
  const { isImageUploadToPhotoZoneOpen, isImageUploadDrawerOpen, photoTrayImagesPage } = appState;
  const [selectedPhotos, setSelectedPhotos] = useState<ImageResponse[]>([]);
  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
  const customFormats = PhotoTrayCustomFormats;
  let isAddingToCanvas = false;

  const [hasMoreImages, setHasMoreImages] = useState(true);
  const { IS_DRAWER_PAGINATION_ENABLED } = useFeatureFlags();
  const observerTarget = useRef(null);
  const footerObserverTarget = <div ref={observerTarget} style={{ height: '1px', marginTop: '150px' }}></div>;
  const { onImageUpload } = useImageUploadHandlers();
  const {
    initializedDataState: { data: initializedData, isUK },
  } = useInitializationDataContext();

  const {
    isUploading,
    setIsUploading,
    onUploadImages,
    photoTrayImagesFinal,
    setPhotoTrayImages,
    handleDeleteImageLocally,
  } = usePhotoDrawerInfiniteScroll(
    appDispatch,
    isImageUploadDrawerOpen,
    isImageUploadToPhotoZoneOpen,
    setHasMoreImages,
    hasMoreImages,
    IS_DRAWER_PAGINATION_ENABLED,
    photoTrayImagesPage,
    observerTarget,
  );

  useEffect(() => {
    if (isImageUploadDrawerOpen || (isImageUploadToPhotoZoneOpen && IS_DRAWER_PAGINATION_ENABLED)) {
      setHasMoreImages(true);
      addPhotoTrayImages(appDispatch, []);
    }
  }, [isImageUploadDrawerOpen, isImageUploadToPhotoZoneOpen, appDispatch, setHasMoreImages]);

  useEffect(() => {
    setHasMoreImages(false);
  }, [closeAllDrawers]);

  const {
    setImagesToDelete,
    deleteImagesConfirmationOpen,
    handleDeleteImagesConfirmation,
    handleOpenDeleteImagesConfirmationDialog,
    isDeleting,
  } = useDeletePhotoTrayImages(setPhotoTrayImages, handleDeleteImageLocally, setIsDeleteMode);

  const onDeleteImages = (photosToDelete: ImageResponse[]) => {
    handleOpenDeleteImagesConfirmationDialog(true);
    setImagesToDelete(photosToDelete);
  };
  const ConfirmDeleteDialog = {
    dialogTitle: t('photoDrawer.deleteDialog.dialogTitle'),
    dialogContent: t('photoDrawer.deleteDialog.dialogContent'),
    confirmButtonText: t('photoDrawer.deleteDialog.actionButton'),
    cancelButtonText: t('photoDrawer.deleteDialog.cancelButton'),
  };
  const NoPhotosUploadedLabels = {
    title: t('photoDrawer.noPhotosUploaded.title'),
    steps: t('photoDrawer.noPhotosUploaded.steps', { returnObjects: true }) as string[],
  };
  const PhotosUploadedLabels = {
    uploadPhoto: t('photoDrawer.photosUploaded.uploadPhoto'),
    deleteImage: t('photoDrawer.photosUploaded.deleteImage'),
    cancel: t('photoDrawer.photosUploaded.cancel'),
  };

  const onUploadImageClick = useCallback(async () => {
    try {
      setIsUploading(true);
      if (inputFileRef.current) {
        const event = { currentTarget: inputFileRef.current } as React.ChangeEvent<HTMLInputElement>;
        await onUploadImages(event);
        const uniqueImages = Array.from(new Set([...photoTrayImagesFinal]));
        setPhotoTrayImages(uniqueImages);
        if (photoTrayImagesFinal.length > 0) {
          setIsUploading(false);
        }
      }
    } catch (error) {
      setIsUploading(false);
    }
  }, [onUploadImages, photoTrayImagesFinal, setIsUploading]);

  const onUploadError = (error: Error) => {
    setIsToasterOpen(appDispatch, {
      title: 'Error uploading image',
      children: error.message,
      variant: ToastVariants.Error,
    });
  };

  const onPhotoSelect = async (photo: ImageResponse) => {
    try {
      if (isAddingToCanvas) {
        return;
      }
      isAddingToCanvas = true;

      const data = {
        image_reference_id: photo.image_reference_id || photo.photo_tray_image_id,
      };

      const response = await linkPhoto(data, initializedData?.project_id as string);

      const imageElement = new window.Image();
      imageElement.crossOrigin = 'anonymous';

      const urlObj = new URL(response.data?.image_url || '');
      urlObj.searchParams.delete('w');
      imageElement.src = urlObj.toString();

      imageElement.onload = () => {
        const imgInstance = new fabric.Image(imageElement, {
          top: 100,
          left: 100,
          name: response.data?.image_id,
          data: {
            type: CanvasDataTypes.UserImage,
          },
          borderColor: isUK ? '#333333' : '#604099',
        });

        if (onImageUpload) {
          onImageUpload(imgInstance);
          closeAllDrawers();
        }
      };
    } catch (error) {
      onUploadError(error as Error);
    }
  };

  const updatedPhotoTrayImages = photoTrayImagesFinal.map((image) => ({
    ...image,
    thumbnail_url: `${image.thumbnail_url}?${thumbnailWidth}`,
  }));

  return (
    <>
      <PhotoDrawerComponent
        onPhotoSelect={onPhotoSelect}
        isOpen={isImageUploadDrawerOpen || isImageUploadToPhotoZoneOpen}
        onUploadedImageClick={onUploadImageClick}
        // TODO: We can probably remove this onDrawerImageUpload prop
        onDrawerImageUpload={() => null}
        onClose={closeAllDrawers}
        title={t('photoDrawer.title') || ''}
        photoTrayImages={updatedPhotoTrayImages}
        onDeleteImages={onDeleteImages}
        onUploadError={onUploadError}
        NoPhotosUploadedLabels={NoPhotosUploadedLabels}
        PhotosUploadedLabels={PhotosUploadedLabels}
        setIsDeleting={setIsDeleteMode}
        isDeleting={isDeleteMode}
        inputFileRef={inputFileRef}
        selectedPhotos={selectedPhotos}
        setSelectedPhotos={setSelectedPhotos}
        acceptedFormats={customFormats}
        isUploading={isUploading}
        footerContent={footerObserverTarget}
        isFooterSticky={true}
        showVeil={false}
        addClass={styles['photo-drawer-container']}
        // Show loading indicator on first image upload or on delete
        isLoading={(isUploading && photoTrayImagesFinal.length < 1) || isDeleting}
      />
      <div ref={observerTarget}></div>
      <DeleteImagesConfirmationDialog
        isOpen={deleteImagesConfirmationOpen}
        onClose={() => handleOpenDeleteImagesConfirmationDialog(false)}
        onConfirm={handleDeleteImagesConfirmation}
        domId="delete-confirmation-dialog"
        contentTitle={ConfirmDeleteDialog.dialogTitle}
        contentText={ConfirmDeleteDialog.dialogContent}
        actionButtonText={ConfirmDeleteDialog.confirmButtonText}
        cancelButtonText={ConfirmDeleteDialog.cancelButtonText}
      />
    </>
  );
};
