import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandColors } from '@hallmark/web.styles.colors';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { setIsImageUploadToPhotoZoneDrawerOpen, setIsWamDrawerOpen, useAppContext } from '../../../context/app-context';
import { setSelectedPhotoZone, useCardContext } from '../../../context/card-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { useFeatureFlags } from '../../../hooks';
import { useDatadog } from '../../../hooks/useDatadog';
import colorsList from '../../../styles/util.scss';
import {
  addUserTextHandlers,
  CanvasDataTypes,
  getCardFaceClipPath,
  handlePhototextzoneSelection,
} from '../../../utils';
import { getTextSettings, removeEditableAreaButtons } from '../../card-editor/utils';
import { ResetTextData } from '../card-face-types';
import { createTextElement } from '../utils/create-text-element';
import { usePhotoTextZoneButtons } from './usePhotoTextZoneButtons';

export const useCanvasMouseUp = ({ cardIndex, fabric, editingHybridBox, setIsResetTextModalOpen }) => {
  const { initializedDataState } = useInitializationDataContext();
  const { cardState, cardDispatch } = useCardContext();
  const { appDispatch } = useAppContext();
  const { trackPhotoUploadMethod } = useAnalyticsContext();
  const { t } = useTranslation();
  const { handleTypeAMessage, handleAddHandwriting } = usePhotoTextZoneButtons();
  const { DISPLAY_ZOOM_DEV, SAS_DYNAMIC_BUTTONS } = useFeatureFlags();
  const datadog = useDatadog();
  const [resetTextData, setResetTextData] = useState<ResetTextData>({
    canvas: null,
    zoneName: '',
  });
  const { isUK } = initializedDataState;
  const defaultTextAreaSettings = { ...initializedDataState.data?.variables.template_data.DefaultTextArea };

  const handleMouseUp = ({ options, canvasRef, canvas }) => {
    //  handles the uploading image for a photo zone
    if (options?.target?.data?.type === CanvasDataTypes.PhotoZoneButton) {
      setSelectedPhotoZone(cardDispatch, options.target.data.photoZoneId);
      setIsImageUploadToPhotoZoneDrawerOpen(appDispatch);
      trackPhotoUploadMethod('camera icon');
    } else if (options?.target?.data?.type === CanvasDataTypes.UserZoneAddTextButton) {
      // Adds an editable text box to the user zone
      if (canvas && canvasRef.current) {
        const INITIAL_TEXT_MESSAGE = `${t('cardEditor.userTextDefaultMessage')}`;
        const cardFaceClipPath = getCardFaceClipPath(cardState.cardFacesList[`${cardIndex}`], 0);
        const defaultFallbackColor = isUK
          ? colorsList[BrandColors.CharcoalGrey]
          : initializedDataState.defaultColor || colorsList[BrandColors.Black];
        const defaultColor = defaultTextAreaSettings?.TextColor || defaultFallbackColor;
        const defaultFontSize = initializedDataState.data?.font_collection.default_size || 50;
        const defaultTextSettings = getTextSettings({
          cardState,
          color: defaultColor,
          defaultFontSize,
          isUK,
          name: CanvasDataTypes.UserText,
        });
        const width = cardFaceClipPath.width;

        const textElement = createTextElement({
          width,
          fabric,
          initialTextMessage: INITIAL_TEXT_MESSAGE,
          isUK,
          defaultTextSettings,
          cardFaceClipPath,
        });

        addUserTextHandlers(textElement, INITIAL_TEXT_MESSAGE, defaultColor, canvasRef.current);

        canvasRef.current.add(textElement);
        canvasRef.current.setActiveObject(textElement);
      }
      removeEditableAreaButtons(canvas);
    } else if (options?.target?.data?.type === CanvasDataTypes.UserZoneAddWamButton) {
      setIsWamDrawerOpen(appDispatch);
    } else if (options?.target?.data?.type === CanvasDataTypes.PhotoTextZoneTextButton) {
      editingHybridBox.current = true;
      handleTypeAMessage(canvas, canvasRef, options?.target, DISPLAY_ZOOM_DEV);
    } else if (options?.target?.data?.type === CanvasDataTypes.PhotoTextZoneWAMButton) {
      handleAddHandwriting(canvas, canvasRef, options?.target);
    } else if (options?.target?.data?.type === CanvasDataTypes.PhotoTextZoneResetButton) {
      if (canvas) {
        setIsResetTextModalOpen(true);
        setResetTextData({
          canvas: canvas,
          zoneName: options?.target?.data?.linkedZoneName,
        });
      }
    } else if (options?.target?.data?.type === CanvasDataTypes.PhotoTextZone) {
      const photoTextZone = options.target as fabric.Group;
      handlePhototextzoneSelection(photoTextZone, canvas, DISPLAY_ZOOM_DEV, SAS_DYNAMIC_BUTTONS);
    } else if (
      options?.target?.data?.type === CanvasDataTypes.PhotoZoneImage ||
      options?.target?.data?.type === CanvasDataTypes.UserImage
    ) {
      datadog.addAction('select-image', {
        image: options.target.data,
      });
    }
  };

  return { resetTextData, handleMouseUp };
};
